import Facebook2SVG from './Facebook2.svg';
import Facebook2OutlinedSVG from './Facebook2Outlined.svg';
import Instagram2SVG from './Instagram2.svg';
import InstagramColorSVG from './InstagramColor.svg';
import InstagramFilledSVG from './InstagramFilled.svg';
import InstagramOutlined from './InstagramOutlined.svg';
import TelegramSVG from './Telegram.svg';
import Telegram2SVG from './Telegram2.svg';
import Telegram24p from './Telegram24p.svg';
import TelegramColorSVG from './TelegramColor.svg';
import Youtube2SVG from './Youtube2.svg';
import YoutubeFilledSVG from './YoutubeFilled.svg';

export {
  Facebook2SVG,
  Instagram2SVG,
  TelegramSVG,
  Telegram2SVG,
  Youtube2SVG,
  TelegramColorSVG,
  InstagramColorSVG,
  InstagramOutlined,
  Telegram24p,
  Facebook2OutlinedSVG,
  InstagramFilledSVG,
  YoutubeFilledSVG,
};
